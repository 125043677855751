<template>
  <div class="px-4">
    <v-card  class="mt-4 white mb-2">
        <h2 class="secondary pa-3">CDPs INFORMATION REG</h2>
      <v-row wrap justify-center class="mt-3">
        <v-col class="d-flex justify-center" >
          <v-btn
          @click="startForm"
           class="mr-2">
            New CDP Information
          </v-btn>
           <v-btn
            @click="startEditing"
           >
            Edit CDP Information
          </v-btn>
        </v-col>
        
      </v-row>
      <div v-if="editForm" class="pa-2 mt-2">
        <div class="mt-3">
          <b class="mt-3">Ref No:</b>
          <v-select
            :items="cdps_info"
            v-model="editCdp"
            @change="dform=editCdp"
             item-text="CDPRef"
            item-value="CDPRef"
            return-object
            outlined
            dense
          ></v-select>
        </div>

      </div>
    </v-card >
    <!-- v-if="newForm" -->
    <v-form class=" mt-3" ref="dform" v-model="valid" lazy-validation>
      <v-card class="pa-4 mx-auto">
      
        <v-row class="mt-2">
          <v-col cols="12">
            <b>Ref No:</b>
            <v-text-field
              :rules="fieldRules"
              v-model.trim="dform.CDPRef"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <b>Community/Group Name:</b>
            <v-text-field
              :rules="fieldRules"
              v-model.trim="dform.GroupName"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Types of project:</b>
            <v-select
              :rules="fieldRules"
              v-model.trim="dform.projects"
              :items="projects"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Environmental Screening Conducted?:</b>
            <v-text-field
              v-if="dform.EnvironmentScreen && dform.EnvironmentScreen != 'Yes'"
              :rules="fieldRules"
              v-model.trim="dform.EnvironmentScreen"
              outlined
              dense
              hint="Give reason"
              persistent-hint
            ></v-text-field>
            <v-radio-group
              v-else
              v-model="dform.EnvironmentScreen"
              row
            >
              <v-radio
                label="Yes"
                value="Yes"
              ></v-radio>
              <v-radio
                label="No"
                value="No"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Social Screening Conducted?:</b>            
            <v-text-field
              v-if="dform.SocialScreen && dform.SocialScreen != 'Yes'"
              :rules="fieldRules"
              v-model.trim="dform.SocialScreen"
              outlined
              dense
              hint="Give reason"
              persistent-hint
            ></v-text-field>
            <v-radio-group
              v-else
              v-model="dform.SocialScreen"
              row
            >
              <v-radio
                label="Yes"
                value="Yes"
              ></v-radio>
              <v-radio
                label="No"
                value="No"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Environmental Audit Conducted?:</b>
            <v-text-field
              v-if="dform.EnvironmentAudit && dform.EnvironmentAudit != 'Yes'"
              :rules="fieldRules"
              v-model.trim="dform.EnvironmentAudit"
              outlined
              dense
              hint="Give reason"
              persistent-hint
            ></v-text-field>
            <v-radio-group
              v-else
              v-model="dform.EnvironmentAudit"
              row
            >
              <v-radio
                label="Yes"
                value="Yes"
              ></v-radio>
              <v-radio
                label="No"
                value="No"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Social Audit Conducted?:</b>            
            <v-text-field
              v-if="dform.SocialAudit && dform.SocialAudit != 'Yes'"
              :rules="fieldRules"
              v-model.trim="dform.SocialAudit"
              outlined
              dense
              hint="Give reason"
              persistent-hint
            ></v-text-field>
            <v-radio-group
              v-else
              v-model="dform.SocialAudit"
              row
            >
              <v-radio
                label="Yes"
                value="Yes"
              ></v-radio>
              <v-radio
                label="No"
                value="No"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Any Conflict in the Community During Project Implementation?:</b>
            <v-textarea
              v-if="dform.CommConflict && dform.CommConflict != 'No'"
              :rules="fieldRules"
              v-model.trim="dform.CommConflict"
              outlined
              dense
              auto-grow
              rows="1"
              hint="What is the nature of the conflict and how was it resolved"
              persistent-hint
            ></v-textarea>
            <v-radio-group
              v-else
              v-model="dform.CommConflict"
              row
            >
              <v-radio
                label="Yes"
                value="Yes"
              ></v-radio>
              <v-radio
                label="No"
                value="No"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Any VLD Protocol Developed:</b>
            <v-text-field
              v-model="dform.VLDProtocol"
              type="number"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Date of Sentization:</b>
            <v-text-field
              :rules="fieldRules"
              v-model.trim="dform.sentization"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <b>Need Assessment Date:</b>
            <v-text-field
              :rules="fieldRules"
              v-model.trim="dform.AssessmentDate"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>CPMC/GPMC Election Date:</b>
            <v-text-field
              :rules="fieldRules"
              v-model.trim="dform.ElectionDate"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Desk Appraisal Date:</b>
            <v-text-field
              :rules="fieldRules"
              v-model.trim="dform.DeskDate"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>LGRC Review Date:</b>
            <v-text-field
              :rules="fieldRules"
              v-model.trim="dform.ReviewDate"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>CDP Submission Date:</b>
            <v-text-field
              :rules="fieldRules"
              v-model.trim="dform.SubmissionDate"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Field Appraisal Date:</b>
            <v-text-field
              :rules="fieldRules"
              v-model.trim="dform.FieldDate"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Date of Approval / MTRC:</b>
            <v-text-field
              :rules="fieldRules"
              v-model.trim="dform.ApprovalDate"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>CPMC/GPMC Training Date:</b>
            <v-text-field
              :rules="fieldRules"
              v-model.trim="dform.TrainingDate"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Project Launch Date:</b>
            <v-text-field
              :rules="fieldRules"
              v-model.trim="dform.LaunchDate"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Total cost of the CDP:</b>
            <v-text-field
              v-model.number="dform.CDPTotalCost"
              outlined
              type="number"
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>Status of CDP:</b>
            <v-select
              :rules="fieldRules"
              v-model.trim="dform.CDPStatus"
              :items="status"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <b>CDP Completed Date:</b>
            <v-text-field
              v-model.trim="dform.CompletedDate"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <b>Approved MPs 1:</b>
            <v-text-field
              :rules="fieldRules"
              v-model.trim="dform.ApprovedMPs1"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <b>Approved MPs 2:</b>
            <v-text-field
              :rules="fieldRules"
              v-model.trim="dform.ApprovedMPs2"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <b>Approved MPs 3:</b>
            <v-text-field
              v-model.trim="dform.ApprovedMPs3"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <b>Comment:</b>
            <v-textarea
              
              v-model.trim="dform.CdpComment"
              rows="2"
              auto-grow
              outlined
              dense
            ></v-textarea>
          </v-col>

          <v-col cols="12">
            <p v-if="dform.id" class="text-end">
              <v-btn :loading="loading" @click="saveChanges" color="success"
                >Save Changes</v-btn
              >
            </p>
            <p v-else class="text-end">
              <v-btn @click="post" :loading="loading" color="success"
                >Submit</v-btn
              >
            </p>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, editUserMixin, rulesMixin } from "@/mixins";
import { mapState, mapGetters } from "vuex";
export default {
  name: "Administrative",
  mixins: [snackMixin, editUserMixin, rulesMixin],

  data() {
    return {
      dform: this.initializeForm(),
      valid: true,
      cdps_info: [],
      newForm: false,
      editForm: false,
      editCdp: {}
    };
  },
   async created() {
    await apiClient.get("/cdps_info?u=2").then((res) => {
      if (Array.isArray(res.data)) {
        this.cdps_info = res.data;
      }
    })
    .catch((err) => this.displayMsg(err.message, "error"));
  },
  
  computed: {
    ...mapState(["projects", "status", "cdps"]),
    ...mapGetters(["getCurUser"]),
  },
  methods: {
    initializeForm() {
      return {
        id: "",
        CDPRef: "",
        GroupName: "",
        projects: "",
        EnvironmentScreen:"",
        SocialScreen:"",
        EnvironmentAudit:"",
        SocialAudit:"",
        CommConflict:"",
        VLDProtocol:0,
        sentization: "",
        AssessmentDate: "",
        ElectionDate: "",
        DeskDate: "",
        ReviewDate: "",
        SubmissionDate: "",
        FieldDate: "",
        ApprovalDate: "",
        TrainingDate: "",
        LaunchDate: "",
        CDPTotalCost: 0.0,
        CDPStatus: "",
        CompletedDate: `1000-01-01`,
        ApprovedMPs1: "",
        ApprovedMPs2: "",
        ApprovedMPs3: "",
        CdpComment: "",
        created_by: "",
        modified_by: "",
      };
    },
   

    async post() {
      if (!this.$refs.dform.validate()) {
        this.displayMsg("Enter All Required Fields", "error");
        return;
      }
      try {
        this.loading = true;
        this.dform.created_by = this.getCurUser;
        let new_cdp = await apiClient.post("/cdps_info", this.dform);
   
        if (new_cdp.data.id) {
          let community_dform = {
            id: "",
            cdps_id: new_cdp.data.id,
            CDPRef: new_cdp.data.CDPRef,
            CommunityRefNo: new_cdp.data.CDPRef,
            CommunityName: new_cdp.data.GroupName,
            created_by: this.getCurUser,
          };
          let cpmc_dform = {
            id: "",
            cdps_id: new_cdp.data.id,
            CDPRef: new_cdp.data.CDPRef,
            CPMCRefNo: new_cdp.data.CDPRef,
            ElectionDate: new_cdp.data.ElectionDate,
            TrainingDate: new_cdp.data.TrainingDate,
            created_by: this.getCurUser,
          };
          let mp_dform = {};
          let mp_dform_required = [
            {
              id: "",
              MPsApproved: new_cdp.data.ApprovedMPs1,
            },
            {
              id: "",
              MPsApproved: new_cdp.data.ApprovedMPs2,
            },
          ];
          let mp_dform_optional = [
            {
              id: "",
              MPsApproved: new_cdp.data.ApprovedMPs1,
            },
            {
              id: "",
              MPsApproved: new_cdp.data.ApprovedMPs2,
            },
            {
              id: "",
              MPsApproved: new_cdp.data.ApprovedMPs3,
            },
          ];
          if (this.dform.ApprovedMPs3) {
            mp_dform = {
              cdps_id: new_cdp.data.id,
              CDPRef: new_cdp.data.CDPRef,
              MPsRefNo: new_cdp.data.CDPRef,
              created_by: this.getCurUser,
              mp_dform_optional,
            };
          } else {
            mp_dform = {
              cdps_id: new_cdp.data.id,
              CDPRef: new_cdp.data.CDPRef,
              MPsRefNo: new_cdp.data.CDPRef,
              created_by: this.getCurUser,
              mp_dform_required,
            };
          }
          let community = await apiClient.post(
            "/community_info",
            community_dform
          );
          let cmpc = await apiClient.post("/cpmc_info", cpmc_dform);
          let allMps = await apiClient.post("/mps_info", mp_dform);
          if (community.data.ok & cmpc.data.ok & allMps.data.ok) {
            this.displayMsg("New CDP created successfully");
            mp_dform_required = [];
            mp_dform_optional = [];
            community_dform = {};
            cpmc_dform = {};
            mp_dform = {};
          } else {
            this.displayMsg(
              "Unable to create record, please try again!",
              "error"
            );
          }
          this.loading = false;
          this.dform = this.initializeForm();
        } else {
          this.displayMsg(new_cdp.data, "error");
        }
      } catch (error) {
        this.displayMsg(error);
      }
    },
    async startForm(){
      this.dform = this.initializeForm();
      this.editForm = false
      this.newForm = true;
    },
    async startEditing(){
      this.CDPRef ='';
      this.newForm = false;
      this.editForm = true;
    },
    saveChanges() {
      this.dform.type = "cdps";
      this.loading = true;
      this.dform.modified_by = this.getCurUser;

      apiClient
        .put("/cdps_info", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.displayMsg("Changes saved successfully.Redirecting ...");
            this.loading = false;
            setTimeout(() => {
              this.$router.push({
                name: "CdpInformationTable",
              });
            }, 3000);
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((err) => {
          this.displayMsg(err.message, "error");
          this.loading = false;
        });
    },
  },
};
</script>
